module.exports = {
  appId: 'VR',
  appName: 'VR',
  title: 'VR',
  cdnServerUrl: 'https://jingxi-pro.oss-cn-shenzhen.aliyuncs.com/',
  logoUrl: '',
  mpAppId: '',
  // appServerUrl: 'http://127.0.0.1:86/',
  appServerUrl: '//wly.momsurprise.com/',
  errorLog: 'production'
}
