import { createApp } from 'vue'
import App from './App.vue'

import store from './store'
import router from './router'

import { 
    ConfigProvider,
    Divider, Empty, Popup, Overlay, Loading, Dialog,
    NavBar, Area, Image as VanImage, ActionSheet, Form, Field, CellGroup, Cell, SwipeCell, Icon,
   Search,
   Stepper, Steps, Step, Card, Checkbox, CheckboxGroup, Button, Swipe, SwipeItem, PullRefresh, Calendar, List, Tab, Tabs, SubmitBar, Toast, Tabbar, TabbarItem,
   Col, Row, Tag, Progress,
   Sidebar, SidebarItem } from 'vant'

// import settings from '@/settings'
// 移动样式
import 'vant/lib/index.css'
import 'lib-flexible/flexible'

import sHeader from '@/components/header/sheader'
import JmcActionSheet from '@/components/sheet/action_sheet'

const app = createApp(App);
app.use(Toast);
app.component('sHeader', sHeader).component('JmcActionSheet', JmcActionSheet);
app.use(ConfigProvider).use(Divider).use(Empty).use(Popup).use(Overlay).use(Progress).use(Steps).use(Search).use(Step).use(Tag).use(Col).use(Row).use(Sidebar).use(SidebarItem).use(Loading).use(Area).use(VanImage).use(NavBar).use(Dialog).use(Toast).use(Form).use(Calendar).use(ActionSheet).use(Field).use(CellGroup).use(Cell).use(SwipeCell).use(Icon).use(Stepper).use(Card).use(Button).use(Swipe).use(SwipeItem).use(PullRefresh).use(List).use(Tab).use(Tabs).use(Tabbar).use(TabbarItem).use(SubmitBar).use(Checkbox).use(CheckboxGroup);

app.use(store).use(router).mount('#app')
