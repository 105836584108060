import axios from 'axios'
import { showToast  } from 'vant'
import store from '@/store'
import { getToken } from '@/utils/auth'
import settings from '@/settings'
// import router from '@/router'
const qs = require('qs')
// const ua = navigator.userAgent.toLocaleLowerCase()
// create an axios instance
const service = axios.create({
  baseURL: settings.appServerUrl, // process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['Accept'] = 'application/json, text/plain, */*'
    const userAgent = window.navigator.userAgent.toLowerCase();
    console.log('userAgent:', userAgent)
    if (store.getters.token) {
      // let each request carry token
      const token = getToken()
      config.headers['token'] = token
    }
    // console.log('config.method : ' + config.method + (config.method === 'post'))
    if (config.method === 'post') {
      // 'post', 'put', 'patch'请求从data(请求体)中获取数据，但spring只能接收post请求数据
      // good job
      config.data = qs.stringify(config.data)
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8' // application/json;charset=UTF-8
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    const res = response.data
    if (!res.code==='00000000') {
      showToast(res.msg || res.desc || 'Error')
      return Promise.reject(new Error(res.msg || res.message || res.desc || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
