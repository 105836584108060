// import { createApp } from 'vue'

// import Router from 'vue-router'

import { createRouter, createWebHashHistory } from 'vue-router'
// import appRoutes from './components/app'
// import groupRoutes from './components/group'

// createApp({}).use(Router)

// import { deepClone } from '../../src/utils/index.js'
// import { asyncRoutes, constantRoutes } from './routes.js'

const newRoutes = [
  {
    path: '/scenic/vr',
    name: 'scenicVr',
    component: () => import('@/pages/vr/vr-index')
  }
]

export const constantRoutes = [
  {
    path: '/',
    name: 'home',
    redirect: 'home'
  },
  {
    path: '/home',
    component: () => import('@/pages/home')
  },
  // {
  //   path: '*',
  //   name: '/404',
  //   redirect: '404'
  // },
  {
    path: "/:catchAll(.*)",
    redirect: "/404"
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '404'
  },
  {
    path: '/404',
    component: () => import('@/pages/error/404'),
    hidden: true
  }
]


export const asyncRoutes = [
  newRoutes,
  /** when your routing map is too long, you can split it into small modules
  agentCategoryRoutes, **/
  {
    path: '/error',
    redirect: 'noRedirect',
    name: 'ErrorPages',
    meta: {
      title: 'Error Pages',
      icon: '404'
    },
    children: [
      // {
      //   path: '401',
      //   component: () => import('@/views/error-page/401'),
      //   name: 'Page401',
      //   meta: { title: '401', noCache: true }
      // },
      // {
      //   path: '404',
      //   component: () => import('@/views/error-page/404'),
      //   name: 'Page404',
      //   meta: { title: '404', noCache: true }
      // }
    ]
  },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const routers = constantRoutes.concat(newRoutes);
console.log('routers:', routers);

const router = createRouter({
  history: createWebHashHistory(),
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: routers// constantRoutes
})

// const createRouter = () => new Router({
//   // mode: 'history', // require service support
//   scrollBehavior: () => ({ y: 0 }),
//   routes: constantRoutes.concat(newRoutes) // constantRoutes
// })

// const router = createRouterV2()

export function resetRouter() {
  const newRouter = router;//createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
